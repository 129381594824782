import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { FiCloudOff, FiCreditCard } from 'react-icons/fi';
import Cards, { Focused } from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

import { URLParamTypes } from 'types/URLParamTypes';

import { postPayment } from 'services/apiCheckout';

import { useScroll } from 'hooks/scroll';
import { useOrder } from 'hooks/order';
import { ToastMessage, useToast } from 'hooks/toast';
import { useIpAddress } from 'hooks/useIpAddress';

import getValidationErrors from 'utils/getValidationErrors';
import schemaValidationCard from 'utils/schemaValidationCard';
import { cardExpiry } from 'utils/helpers';

import Button from 'components/Button';
import { Input, InputMask } from 'components/Input';
import Loading from 'components/Loading';
import Checkbox from 'components/Checkbox';
import RadioButtonWithoutBulltet from 'components/RadioButtonWithoutBulltet';
import { useConnection } from 'hooks/connection';
import {
  PAYMENT_STATUS_APPROVED,
  PAYMENT_STATUS_AUTHORIZED,
} from 'utils/constants';

import { issuersNotAllowed } from './issuers';
import { handlePaymentOptions } from './paymentOptions';
import { FormData, RadioOption } from './types';

import { Container, CardWrapper } from './styles';
import { toastsMessages } from './toastsMessages';
import Ame from './Ame';

/**
 * Componente para gerenciar o formulário de pagamento e a comunicação com a API Checkout.
 * Recebe dados da Context API via o hook useOrder()
 */

type FocusedCC = 'cc-name' | 'cc-number' | 'cc-exp' | 'cc-csc';

const PaymentSection = () => {
  const { id, host } = useParams<URLParamTypes>();
  const formRef = useRef<FormHandles>(null);

  /**
   * Custom hooks
   */
  const {
    status,
    formattedTotalValue,
    handleStatus,
    paymentMethods,
  } = useOrder();
  const [ipAddress] = useIpAddress();
  const { addToast } = useToast();
  const [executeScroll, htmlElRef] = useScroll();
  const { isOnline } = useConnection();

  /**
   * States
   */
  const [paymentMethod, setPaymentMethod] = useState<'card' | 'ame'>();
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [focus, setFocus] = useState<Focused>();
  const [issuer, setIssuer] = useState<string>();
  const [initialData, setInitialData] = useState<{
    paymentMethod?: 'card' | 'ame';
  }>();
  const [cardData, setCardData] = useState({
    'cc-csc': '',
    'cc-exp': '',
    'cc-name': '',
    'cc-number': '',
  });

  useEffect(() => {
    if (paymentMethods.card) {
      setPaymentMethod('card');
      setInitialData(oldState => ({ ...oldState, paymentMethod: 'card' }));
      return;
    }
    if (paymentMethods.ame) {
      setPaymentMethod('ame');
      setInitialData(oldState => ({ ...oldState, paymentMethod: 'ame' }));
      return;
    }

    setPaymentMethod(undefined);
  }, [paymentMethods.ame, paymentMethods.card]);

  /**
   * Dynamic variables
   */

  const labelButton = useMemo(() => `Fechar pedido - ${formattedTotalValue}`, [
    formattedTotalValue,
  ]);

  const issuerNotAllowed = useMemo(
    () => issuer && issuersNotAllowed.includes(issuer),
    [issuer]
  );

  const disableSubmit = useMemo(
    () => issuerNotAllowed || loadingPayment || !isOnline,
    [issuerNotAllowed, loadingPayment, isOnline]
  );

  const issuerErrors = useMemo(
    () => (issuerNotAllowed ? 'Bandeira não aceita' : ''),
    [issuerNotAllowed]
  );

  // Checa se já inseriu valor para não sobrepor a mensagem de validação do YUP.
  useEffect(
    () =>
      typeof issuer !== 'undefined'
        ? formRef.current?.setFieldError('number', issuerErrors)
        : // eslint-disable-next-line @typescript-eslint/no-empty-function
          () => {},
    [issuer, issuerNotAllowed, issuerErrors, formRef]
  );

  /**
   * Handles
   */
  const handlePayment = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as 'card' | 'ame';
    setPaymentMethod(value);
  };

  const handleInputFocus = (name: FocusedCC) => {
    let key: Focused | undefined;

    switch (name) {
      case 'cc-name':
        key = 'name';
        break;

      case 'cc-number':
        key = 'number';
        break;

      case 'cc-exp':
        key = 'expiry';
        break;

      case 'cc-csc':
        key = 'cvc';
        break;

      default:
        key = undefined;
        break;
    }

    setFocus(key);
  };

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      const focusedInputName = e.currentTarget.autocomplete;

      focusedInputName &&
        setCardData({ ...cardData, [focusedInputName]: value });
    },
    [cardData]
  );

  const validateCardFields = async (data: FormData) =>
    // eslint-disable-next-line no-return-await
    await schemaValidationCard.validate(data, {
      abortEarly: false,
    });

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoadingPayment(true);

        data.payment_type = 'online';

        formRef.current?.setErrors({});

        const isPaymentOnline = true;

        isPaymentOnline && (await validateCardFields(data));

        const {
          payment_type,
          socialid,
          'cc-number': number,
          'cc-name': name,
          'cc-exp': expiry,
          'cc-csc': cvc,
        } = data;

        if (issuerNotAllowed) {
          addToast(toastsMessages.issuerNotAllowed);

          setLoadingPayment(false);

          return;
        }

        const [month, year] = expiry.split('/');

        const newDate =
          year.length === 2 ? `${month}/20${year}` : `${month}/${year}`;

        // Separando tipo de pagamento

        const paymentMainData = {
          is_document_on_receipt: socialid.length > 0,
          payment_type,
        };

        const paymentCreditCardData = {
          ...paymentMainData,
          payment_info: {
            method: 'credit_card',
            installments: 1,
            ip_address: await ipAddress(),
            credit_card: {
              number: number.replace(/\s/g, ''),
              holder_name: name,
              expiration_date: newDate,
              security_code: cvc,
              brand: issuer,
            },
          },
        };

        const paymentData = () =>
          isPaymentOnline ? paymentCreditCardData : paymentMainData;

        // console.log(paymentCreditCardData);
        // return;

        const response = await postPayment({ id, host, data: paymentData() });

        const { status: statusData } = response.data;

        const approvedStatus = [
          'Pagamento aprovado',
          'Pedido confirmado',
          PAYMENT_STATUS_APPROVED,
          PAYMENT_STATUS_AUTHORIZED,
        ];
        const isApproved = approvedStatus.includes(statusData);

        if (isApproved) {
          addToast(toastsMessages.paymentApproved);

          handleStatus({ type: 'approved', label: 'Pagamento aprovado' });
        }

        if (!isApproved) {
          const errorMessage: Omit<ToastMessage, 'id'> = {
            type: 'error',
            title: 'Pagamento não aprovado.',
            description:
              response.data?.message || 'Ocorreu um erro ao fazer o pagamento.',
          };

          addToast(errorMessage);
        }

        setLoadingPayment(false);
      } catch (err) {
        setLoadingPayment(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          console.log(errors);

          executeScroll();

          addToast(toastsMessages.somethingWrong);

          return;
        }

        addToast(toastsMessages.paymentError);
      }
    },
    [
      issuer,
      addToast,
      executeScroll,
      handleStatus,
      ipAddress,
      id,
      host,
      issuerNotAllowed,
    ]
  );

  const getPaymentOptions: RadioOption[] = useMemo(
    () => handlePaymentOptions(paymentMethods),
    [paymentMethods]
  );

  return (
    <Container isOnline={isOnline}>
      {!paymentMethod && (
        <form>
          <Button disabled template="warning">
            Esta loja não tem método de pagamento online.
          </Button>
        </form>
      )}

      {initialData && (
        <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
          <header>
            <aside>
              <FiCreditCard />
            </aside>

            <h3>Pagamento</h3>
          </header>

          {getPaymentOptions.length > 1 && (
            <RadioButtonWithoutBulltet
              name="paymentMethod"
              options={getPaymentOptions}
              onChange={handlePayment}
              disabled={!isOnline}
            />
          )}

          {paymentMethod === 'card' && paymentMethods.card && (
            <>
              <CardWrapper open ref={htmlElRef}>
                <Cards
                  focused={focus}
                  cvc={cardData['cc-csc']}
                  expiry={cardData['cc-exp']}
                  name={cardData['cc-name']}
                  number={cardData['cc-number']}
                  placeholders={{ name: 'Nome impresso no cartão' }}
                  locale={{ valid: 'Valido até' }}
                  callback={({ issuer: issuerLabel }) => setIssuer(issuerLabel)}
                />
                <div className="wrapperFields">
                  <InputMask
                    format="#### #### #### #######"
                    label="Número do cartão"
                    type="tel"
                    name="cc-number"
                    onChange={handleInputChange}
                    handleFocus={handleInputFocus}
                    autoComplete="cc-number"
                  />

                  <Input
                    type="text"
                    label="Nome do titular"
                    minLength={2}
                    maxLength={26}
                    name="cc-name"
                    onChange={handleInputChange}
                    handleFocus={handleInputFocus}
                    autoComplete="cc-name"
                  />

                  <InputMask
                    format={cardExpiry}
                    mask="_"
                    type="tel"
                    label="Validade"
                    name="cc-exp"
                    onChange={handleInputChange}
                    handleFocus={handleInputFocus}
                    autoComplete="cc-exp"
                    placeholder="MM/AAAA"
                  />

                  <Input
                    type="tel"
                    label="CVV"
                    name="cc-csc"
                    size={4}
                    maxLength={4}
                    onChange={handleInputChange}
                    handleFocus={handleInputFocus}
                    autoComplete="cc-csc"
                  />
                </div>
              </CardWrapper>

              <Checkbox
                name="socialid"
                options={[
                  {
                    id: 'cpfcnpj',
                    value: 'cpfcnpj',
                    label: 'Adicionar CPF/CNPJ na nota',
                  },
                ]}
              />

              <section className={`submit fixed ${status.type}`}>
                {isOnline && (
                  <Button
                    className={`${status.type}`}
                    type="submit"
                    disabled={disableSubmit}
                  >
                    {loadingPayment ? (
                      <Loading inline color="#ffffff">
                        Processando pagamento...
                      </Loading>
                    ) : (
                      labelButton
                    )}
                  </Button>
                )}

                {!isOnline && (
                  <Button
                    className={`${status.type}`}
                    type="button"
                    disabled
                    template="warning"
                  >
                    <Loading inline color="#ffffff">
                      <FiCloudOff /> Sem conexão com a internet
                    </Loading>
                  </Button>
                )}
              </section>
            </>
          )}

          {paymentMethod === 'ame' && <Ame />}
        </Form>
      )}
    </Container>
  );
};

export default PaymentSection;
