import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.light};
    padding: 2rem;
    margin: 1rem 0;
    border-radius: ${theme.border.radius.large};

    .ame-qrcode-wrapper {
      width: 100%;
      max-width: 340px;
      margin: 40px auto;
    }

    .ame-qrcode-wrapper p,
    .ame-qrcode-wrapper strong,
    .ame-qrcode-wrapper span {
      font-size: 12px;
    }

    .ame-qrcode-wrapper .logo {
      display: block;
      margin: 0 auto;
    }

    .ame-qrcode-wrapper .container {
      margin-top: 20px;
      padding: 20px;
      border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 15px;
    }

    .ame-qrcode-wrapper .payment-values {
      text-align: center;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .ame-qrcode-wrapper .payment-values strong {
      display: block;
    }

    .ame-qrcode-wrapper .payment-values strong:first-child {
      margin-bottom: 5px;
    }

    .ame-qrcode-wrapper .payment-values strong span {
      color: #ed555d;
      font-size: 16px;
    }

    .ame-qrcode-wrapper .payment-values p strong {
      display: inline;
      color: #4a90e2;
    }

    .ame-qrcode-wrapper .loading {
      padding: 20px;
      border-radius: 8px;
      margin-bottom: 20px;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 15px;
      font-size: 12px;
    }

    .ame-qrcode-wrapper img.qrcode {
      display: block;
      margin: 10px auto;
    }

    .ame-qrcode-wrapper .deeplink {
      text-align: center;
      margin: 10px auto 20px;

      a {
        color: #ed555d;
        font-size: 16px;
      }
    }

    .ame-qrcode-wrapper .container .alert {
      text-align: center;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1;
      border-radius: 0.25rem;
      padding: 0.5rem 0.75rem;

      .icon {
        display: flex;
        margin-right: 4px;
      }

      &.error {
        background-color: ${theme.colors.danger};
        color: ${theme.colors.white};
      }
    }

    .ame-qrcode-wrapper .payment-info > strong {
      color: #ed555d;
      font-size: 16px;
      text-align: center;
      text-align: justify;
    }

    .ame-qrcode-wrapper .payment-info ul {
      list-style: none;
      padding: 0;
    }

    .ame-qrcode-wrapper .payment-info ul li {
      color: #666;
      font-size: 12px;
      margin-bottom: 10px;
    }

    .ame-qrcode-wrapper .payment-info ul li p {
      height: 15px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 5px 0;
    }

    .ame-qrcode-wrapper .payment-info ul li strong,
    .ame-qrcode-wrapper .payment-info ul li img {
      margin: 0 2px;
    }

    .ame-qrcode-wrapper a {
      color: #666;
      font-size: 12px;
    }
  `}
`;
