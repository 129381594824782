import styled, { css } from 'styled-components';

import { device } from 'styles/device';

export const Container = styled.div`
  ${({ theme }) => css`
    position: fixed;
    right: 0;
    top: 0;
    z-index: ${theme.layers.alwaysOnTop};
    overflow: hidden;

    padding: ${theme.spacings.medium};
    max-width: 100vw;

    @media ${device.laptop} {
      padding: ${theme.spacings.xxlarge};
    }
  `}
`;
