import styled, { css, keyframes, DefaultTheme } from 'styled-components';

const approved = (theme: DefaultTheme) => keyframes`
  10%{
    height: calc(${theme.spacings.xxlarge} * 2);
  }
  50% {
   height: 100vh;
  }
  100% {
    top: 0;
    height: 10rem;
  }
`;
interface ContainerProps {
  isOnline?: boolean;
}

export const Container = styled.section<ContainerProps>`
  ${({ theme, isOnline }) => css`
    border-top: 1px solid ${theme.colors.light};
    margin-bottom: ${theme.spacings.xxlarge};

    opacity: ${isOnline ? 1 : 0.75};

    transition: all ${theme.transition.default};

    .statusComponent {
      background: ${theme.colors.success};
    }

    header {
      display: flex;
      align-items: center;
      margin-top: 1rem;
      margin-bottom: ${theme.spacings.small};
      padding: ${theme.spacings.medium} 0 0;

      aside {
        display: flex;
        align-items: center;
        justify-content: center;

        margin-right: ${theme.spacings.xsmall};

        width: ${theme.spacings.large};
        min-width: ${theme.spacings.large};

        color: ${theme.colors.primary};

        img {
          width: ${theme.spacings.large};
          height: ${theme.spacings.large};
          object-fit: cover;
        }

        svg {
          font-size: ${theme.font.sizes.xxlarge};
        }
      }

      h3 {
        font-size: ${theme.font.sizes.medium};
        font-weight: ${theme.font.bolder};
        color: ${theme.colors.dark};
      }
    }

    form {
      padding: 0 ${theme.spacings.medium};

      .listRadio {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, auto));
        grid-gap: ${theme.spacings.medium};
        margin-bottom: ${theme.spacings.medium};
      }

      .fixed {
        position: fixed;
        left: 50%;
        bottom: 0;
        z-index: ${theme.layers.alwaysOnTop};
        transform: translateX(-50%);

        display: flex;
        justify-content: center;
        align-items: center;

        height: calc(${theme.spacings.xxlarge} * 2);
        width: 100%;
        max-width: ${theme.grid.container};
        padding: ${theme.spacings.xsmall} ${theme.spacings.medium};

        border-top: 1px solid ${theme.colors.tertiary};
        background: ${theme.colors.white};

        transition: all ${theme.transition.default};

        button {
          margin: 0;
          max-width: calc(${theme.grid.container} / 2);
        }

        &.approved {
          padding: 0;
          border: 0;
          animation: ${approved(theme)} 2s ease-in-out forwards;

          button {
            border-radius: 0;
            max-width: 100%;
            height: 100%;
            border: none;
          }
        }
      }
    }
  `}
`;

type CardWrapperStyleProps = { open: boolean };

export const CardWrapper = styled.div<CardWrapperStyleProps>`
  ${({ theme, open }) => css`
    overflow: hidden;

    margin-bottom: ${open ? theme.spacings.medium : '0'};

    max-height: ${open ? '100vh' : '0'};
    width: 100%;

    opacity: ${open ? '1' : '0'};
    transition: all ${theme.transition.fast};

    .wrapperFields {
      display: flex;
      flex-flow: wrap;
      justify-content: space-between;

      margin-top: ${theme.spacings.medium};

      label {
        width: 100%;

        &:nth-child(3) {
          width: calc(100% - 30% - 1rem);
        }
        &:last-child {
          width: calc(30%);
        }
      }
    }
  `}
`;
