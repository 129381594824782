import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { URLParamTypes } from 'types/URLParamTypes';

import {
  STATUS_APPROVED_VIRTUAL,
  STATUS_CANCELLED,
  PAYMENT_STATUS_WAITING,
  PAYMENT_STATUS_REFUSED,
} from 'utils/constants';

import { useToast } from 'hooks/toast';
import { useOrder } from 'hooks/order';

import MainHelmet from 'components/Helmet';
import Loading from 'components/Loading';
import HeaderStatus from 'components/HeaderStatus';

import Header from './elements/Header';
import AddressSection from './elements/AddressSection';
import ShipmentsSection from './elements/ShipmentsSection';
import ProductsList from './elements/ProductsList';
import ValuesList from './elements/ValuesList';
import PaymentSection from './elements/PaymentSection';

import { Container } from './styles';
import ConnectionSection from './elements/ConnectionSection';

const Order: React.FC = (): JSX.Element => {
  const { getOrderData, loading, error, status, payments } = useOrder();

  const { id, host } = useParams<URLParamTypes>();

  const { addToast } = useToast();

  useEffect(() => {
    try {
      getOrderData({ id, host });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Algo de errado aconteceu',
        description: 'Houve um erro ao carregar seu pedido.',
      });
    }
  }, [getOrderData, id, host, addToast]);

  const isWaitingPayment = useMemo(
    () =>
      payments.status === PAYMENT_STATUS_WAITING ||
      payments.status === PAYMENT_STATUS_REFUSED,
    [payments]
  );

  const statusType = useMemo(() => status?.type, [status]);

  const isApprovedOrder = useMemo(
    () => status.type === STATUS_APPROVED_VIRTUAL,
    [status]
  );

  const isCancelledOrder = useMemo(() => status.type === STATUS_CANCELLED, [
    status,
  ]);

  const showHeaderStatus = useMemo(
    () => !isWaitingPayment || isApprovedOrder || isCancelledOrder,
    [isWaitingPayment, isApprovedOrder, isCancelledOrder]
  );

  const showPaymentSection = useMemo(
    () => isWaitingPayment && !isApprovedOrder && !isCancelledOrder,
    [isWaitingPayment, isApprovedOrder, isCancelledOrder]
  );

  const contentIsReady = useMemo(() => !loading && !error, [loading, error]);

  return (
    <Container className={statusType}>
      <MainHelmet />

      {loading && <Loading>Carregando informações do pedido</Loading>}

      {contentIsReady && (
        <>
          {showHeaderStatus && <HeaderStatus />}

          <Header />

          <main>
            <AddressSection />

            <ShipmentsSection />

            <ProductsList />

            <ValuesList />

            {showPaymentSection && <PaymentSection />}

            <ConnectionSection />
          </main>
        </>
      )}

      {error && 'Houve um erro ao carregar seu pedido.'}
    </Container>
  );
};

export default Order;

// 4024 0071 5376 3191
