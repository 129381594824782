import styled, { css } from 'styled-components';

import { SpinnerStylesProps } from '.';

export const Container = styled.div<SpinnerStylesProps>`
  @-webkit-keyframes spinner-grow {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    50% {
      opacity: 1;
    }
  }

  @keyframes spinner-grow {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    50% {
      opacity: 1;
    }
  }

  ${({ theme, color }) => css`
    display: inline-block;
    vertical-align: text-bottom;

    width: ${theme.spacings.large};
    height: ${theme.spacings.large};
    border-radius: ${theme.border.radius.rounded};
    margin-right: ${theme.spacings.small};

    background-color: ${color || theme.colors.secondary};
    opacity: 0;

    animation: spinner-grow 0.75s linear infinite;
    -webkit-animation: spinner-grow 0.75s linear infinite;
  `}
`;

export const ContainerCircle = styled.div`
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  ${({ theme }) => css`
    width: calc(${theme.spacings.large} * 2);
    height: calc(${theme.spacings.large} * 2);

    margin-right: ${theme.spacings.small};

    border: ${theme.spacings.xxsmall} solid ${theme.colors.primary};
    border-top: 0.25rem solid ${theme.colors.secondary};

    border-radius: ${theme.border.radius.rounded};

    animation: spin 1s linear infinite;
  `}
`;
