import styled, { css, DefaultTheme } from 'styled-components';
import { animated } from 'react-spring';

interface ContainerProps {
  type?: 'success' | 'error' | 'info';
  hasDescription: boolean;
}

const toastTypeVariations = {
  info: (theme: DefaultTheme) => css`
    --bgColor: ${theme.colors.infoLighten50};
    --textColor: ${theme.colors.info};
  `,
  success: (theme: DefaultTheme) => css`
    --bgColor: ${theme.colors.successLighten40};
    --textColor: ${theme.colors.successShade20};
  `,
  error: (theme: DefaultTheme) => css`
    --bgColor: ${theme.colors.dangerLighten40};
    --textColor: ${theme.colors.danger};
  `,
  hasDescription: () => css`
    align-items: center;

    svg {
      margin-top: 0;
    }
  `,
};

export const AnimatedContainer = styled(animated.div)`
  position: relative;
`;

export const Container = styled.div<ContainerProps>`
  ${({ theme, type, hasDescription }) => css`
    display: flex;

    max-width: 90vw;
    width: 36rem;
    border-radius: ${theme.border.radius.medium};
    padding: ${theme.spacings.medium} ${theme.spacings.large}
      ${theme.spacings.medium} ${theme.spacings.medium};

    margin-bottom: ${theme.spacings.xsmall};

    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);

    ${toastTypeVariations[type || 'info'](theme)}

    & + div {
      margin-top: ${theme.spacings.xsmall};
    }

    /* Variables defined on toastTypeVariations */
    background: var(--bgColor);
    color: var(--textColor);

    > svg {
      margin: ${theme.spacings.xxsmall} ${theme.spacings.small} 0 0;
    }

    div {
      flex: 1;

      p {
        margin-top: ${theme.spacings.xxsmall};
        line-height: 2rem;

        font-size: ${theme.font.sizes.small};

        opacity: 0.8;
      }
    }

    /* Close button */
    button {
      position: absolute;
      right: ${theme.spacings.medium};
      top: ${theme.spacings.medium};

      border: 0;
      background: transparent;
      opacity: 0.6;

      color: inherit;
    }

    ${!hasDescription && toastTypeVariations.hasDescription()}

    /* Animação do tempo para fechar */

    @-webkit-keyframes timeToCloseToast {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }
    @keyframes timeToCloseToast {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }

    &:after {
      position: absolute;
      height: 2px;
      background-color: var(--textColor);
      bottom: 0;
      left: 0;
      content: '';
      opacity: 0.5;

      animation: timeToCloseToast 5s linear forwards;
    }
  `}
`;
