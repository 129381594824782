import styled, { css } from 'styled-components';

export const Section = styled.section`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    padding: 1.2rem 1.6rem;

    border-bottom: 1px solid ${theme.colors.tertiary};
    background-color: ${theme.colors.white};

    /* Icon or imagem column */
    aside {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: ${theme.spacings.small};

      width: ${theme.spacings.xxlarge};
      min-width: ${theme.spacings.xxlarge};
      height: ${theme.spacings.xxlarge};

      color: ${theme.colors.primary};

      svg {
        font-size: ${theme.font.sizes.xxlarge};
      }
    }

    /* Main column */
    .infos {
      display: inline-flex;
      flex-direction: column;

      strong {
        font-size: ${theme.font.sizes.small};
        font-weight: ${theme.font.bold};

        &:first-letter {
          text-transform: uppercase;
        }
      }

      span {
        font-size: ${theme.font.sizes.xsmall};
        font-weight: ${theme.font.light};
        color: ${theme.colors.dark};
        margin-top: ${theme.spacings.xxsmall};

        &.tag {
          font-weight: ${theme.font.bold};
          color: ${theme.colors.primary};
        }
      }
    }
  `}
`;
