import React from 'react';
import { FiCheckCircle } from 'react-icons/fi';

import { STATUS_PENDING } from 'utils/constants';

import { useOrder } from 'hooks/order';

import { Container } from './styles';

const HeaderStatus = () => {
  const { status } = useOrder();

  return (
    <Container className={`statusComponent ${status.type}`}>
      {status.type !== STATUS_PENDING && <FiCheckCircle />}

      {status.label}
    </Container>
  );
};

export default HeaderStatus;
