import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from 'styled-components';

import AppProvider from './hooks';

import Routes from './routes';

import theme from './styles/theme';
import GlobalStyles from './styles/global';

const App: React.FC = () => (
  <Router>
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <Helmet defaultTitle="Checkout App" titleTemplate="%s | Checkout" />
        <AppProvider>
          <Routes />
        </AppProvider>
      </HelmetProvider>
      <GlobalStyles />
    </ThemeProvider>
  </Router>
);

export default App;
