import React from 'react';

import { OrderProvider } from './order';
import { ToastProvider } from './toast';
import { ConnectionProvider } from './connection';

const AppProvider: React.FC = ({ children }) => (
  <OrderProvider>
    <ToastProvider>
      <ConnectionProvider>{children}</ConnectionProvider>
    </ToastProvider>
  </OrderProvider>
);

export default AppProvider;
