import styled, { css } from 'styled-components';

interface HeaderProps {
  bgColor: string;
}

export const Container = styled.header<HeaderProps>`
  ${({ theme, bgColor }) => css`
    width: 100%;

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;

      height: calc(${theme.spacings.xxlarge} * 2.5);
      max-width: ${theme.grid.container};
      width: 100%;

      background-color: ${bgColor ? bgColor : theme.colors.white};

      img {
        max-height: calc(
          ${theme.spacings.xxlarge} * 2 - ${theme.spacings.xxsmall}
        );
      }
    }

    .infos {
      position: relative;

      padding: 1.5rem 1rem;

      background-color: ${theme.colors.white};

      h1 {
        font-size: ${theme.font.sizes.medium};
        font-weight: ${theme.font.bolder};
        color: ${theme.colors.dark};

        span {
          font-weight: ${theme.font.bold};
          color: ${theme.colors.gray};
        }
      }
    }
  `}
`;
