import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useMemo,
} from 'react';

import * as I from 'types/order';
import { Store } from 'types/store';
import { URLParamTypes } from 'types/URLParamTypes';

import { getOrder } from 'services/apiCheckout';
import { formatMoney } from 'utils/helpers';

interface OrderContextData {
  loading: boolean;
  error: boolean;
  items: I.Items;
  order: I.Order;
  values: I.Values;
  customer: I.Customer;
  address: I.Address;
  shipments: I.Shipment[];
  store: Store;
  status: I.Status;
  payments: I.Payments;
  location: I.Location;
  paymentMethods: I.PaymentMethods;
  formattedTotalValue: string;
  handleStatus(newStatus: I.Status): void;
  getOrderData({ id, host }: URLParamTypes): void;
}

const OrderContext = createContext<OrderContextData>({} as OrderContextData);

const OrderProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [items, setItems] = useState<I.Items>({} as I.Items);
  const [order, setOrder] = useState<I.Order>({} as I.Order);
  const [values, setValues] = useState<I.Values>(Object);
  const [customer, setCustomer] = useState<I.Customer>(Object);
  const [address, setAddress] = useState<I.Address>(Object);
  const [shipments, setShipments] = useState<I.Shipment[]>([]);
  const [store, setStore] = useState<Store>({} as Store);
  const [status, setStatus] = useState<I.Status>({} as I.Status);
  const [payments, setPayments] = useState<I.Payments>({} as I.Payments);
  const [location, setLocation] = useState<I.Location>({} as I.Location);
  const [paymentMethods, setPaymentMethods] = useState<I.PaymentMethods>(
    {} as I.PaymentMethods
  );

  const getOrderData = useCallback(async ({ id, host }: URLParamTypes) => {
    try {
      const { data } = await getOrder({ id, host });

      const {
        store_config: storeConfig,
        order: fullOrder,
      }: { store_config: Store; order: I.OrderRaw } = data;

      const {
        items: itemsPerStatus,
        status: statusOriginal,
        customer: customerOriginal,
        values: valuesOriginal,
        address: addressOriginal,
        shipments: shipmentsOriginal,
        payments: paymentsOriginal,
        location: locationOriginal,
        payment_method,
        ...rest
      } = fullOrder;

      const {
        confirmed,
        substitution,
        stock_out: stockOut,
        ...restItems
      } = itemsPerStatus;

      const arrayItems = Object.keys(restItems).map(
        (key: string) => restItems[key as any]
      );

      setItems({
        confirmed,
        substitution,
        stockOut,
        restItems: arrayItems,
      });
      setCustomer(customerOriginal);
      setValues(valuesOriginal);
      setStatus(statusOriginal);
      setAddress(addressOriginal);
      shipmentsOriginal && setShipments(shipmentsOriginal);
      setOrder({ ...rest });
      setPayments(paymentsOriginal);
      setLocation(locationOriginal);

      setStore(storeConfig);

      const handlePaymentMethod = () => {
        const card = payment_method.hasCardPayment;
        const ame = payment_method.wallets.some(item => item === 'ame');

        return { card, ame };
      };

      setPaymentMethods(handlePaymentMethod());

      setLoading(false);
    } catch (err) {
      setError(true);

      // eslint-disable-next-line no-console
      console.log(err);

      setLoading(false);
    }
  }, []);

  const handleStatus = (newStatus: I.Status) => setStatus(newStatus);

  const formattedTotalValue = useMemo(() => formatMoney(values?.total || 0), [
    values,
  ]);

  return (
    <OrderContext.Provider
      value={{
        loading,
        error,
        items,
        order,
        values,
        customer,
        address,
        shipments,
        store,
        status,
        payments,
        location,
        formattedTotalValue,
        paymentMethods,
        getOrderData,
        handleStatus,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

function useOrder(): OrderContextData {
  const context = useContext(OrderContext);

  if (!context) {
    throw new Error('useOrder must be used within an OrderProvider');
  }

  return context;
}

export { OrderContext, OrderProvider, useOrder };
