import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getQrCodeWalletAme } from 'services/apiCheckout';
import { URLParamTypes } from 'types/URLParamTypes';
import { formatMoney } from 'utils/helpers';

const useAme = () => {
  const [qrCode, setQrCode] = useState(null);
  const [deepLink, setDeepLink] = useState<string | null>(null);
  const [cashback, setCashback] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { id, host } = useParams<URLParamTypes>();

  const getAmeData = useCallback(async () => {
    try {
      setLoading(true);

      const body = {
        payment_info: {
          wallet: 'ame',
        },
      };

      const { data } = await getQrCodeWalletAme({ id, host, body });

      if (data.qr_code_link) {
        setQrCode(data.qr_code_link);
      }

      if (data.deep_link) {
        setDeepLink(data.deep_link);
      }

      if (data.cashback) {
        setCashback(formatMoney(data.cashback));
      }
    } catch (err) {
      setError(true);

      // eslint-disable-next-line no-console
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [host, id]);

  useEffect(() => {
    if (!qrCode) {
      getAmeData();
    }
  }, [getAmeData, qrCode]);

  return { qrCode, deepLink, cashback, loading, error } as {
    qrCode: string | null;
    deepLink: string | null;
    cashback: string | null;
    loading: boolean;
    error: boolean;
  };
};

export default useAme;
