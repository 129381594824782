import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Order from '../pages/Order';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/checkout/:id/:host?" component={Order} />
    </Switch>
  );
};

export default Routes;
