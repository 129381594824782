import * as Yup from 'yup';

const schemaValidationCard = Yup.object().shape({
  payment_type: Yup.string().required('Forma de pagamento obrigatória.'),
  'cc-number': Yup.string().when(
    'payment_type',
    (payment_type: string, field: Yup.StringSchema) =>
      payment_type === 'online'
        ? field.required('Campo obrigatório')
        : field.nullable()
  ),
  'cc-name': Yup.string().when(
    'payment_type',
    (payment_type: string, field: Yup.StringSchema) =>
      payment_type === 'online'
        ? field
            .required('Campo obrigatório')
            .min(2, 'Tamanho incorreto')
            .max(26)
        : field.nullable()
  ),
  'cc-exp': Yup.string().when(
    'payment_type',
    (payment_type: string, field: Yup.StringSchema) =>
      payment_type === 'online'
        ? field.required('Campo obrigatório').min(4, 'Formato MM/AA')
        : field.nullable()
  ),
  'cc-csc': Yup.string().when(
    'payment_type',
    (payment_type: string, field: Yup.StringSchema) =>
      payment_type === 'online'
        ? field.required('Obrigatório')
        : field.nullable()
  ),
});

export default schemaValidationCard;
