import React, { useEffect, useRef, useState, useCallback } from 'react';
// import ReactInputMask, { Props as InputProps } from 'react-input-mask';
import NumberFormat, {
  NumberFormatProps as InputProps,
} from 'react-number-format';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';

import { Container, Error } from './styles';

interface Props extends InputProps {
  name: string;
  label?: string;
  icon?: React.ComponentType<IconBaseProps>;
  handleFocus: Function;
}

const InputMask: React.FC<Props> = ({
  name,
  icon: Icon,
  handleFocus,
  onChange,
  label,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, registerField, defaultValue, error } = useField(name);

  const handleInputFocus = useCallback(() => {
    if (handleFocus) {
      handleFocus(name);
    }

    setIsFocused(true);
  }, [handleFocus, name]);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref: any, value: string) {
        ref.setInputValue(value);
      },
      clearValue(ref: any) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container isFocused={isFocused} isFilled={isFilled} isErrored={!!error}>
      {label && label}
      <div>
        {Icon && <Icon />}

        <NumberFormat
          onFocus={handleInputFocus}
          onChange={onChange}
          getInputRef={inputRef}
          defaultValue={defaultValue}
          onBlur={handleInputBlur}
          {...rest}
        />

        {error && (
          <Error title={error}>
            <FiAlertCircle />
          </Error>
        )}
      </div>
    </Container>
  );
};

export default InputMask;
