import React, { useMemo } from 'react';
import { useOrder } from 'hooks/order';

import { Container } from './styles';

const Header = () => {
  const { order, store } = useOrder();

  const title = useMemo(() => store?.name, [store]);
  const bgColor = useMemo(() => store?.header_color || '#ffffff', [store]);
  const logo = useMemo(() => store?.logo, [store]);

  return (
    <Container bgColor={bgColor}>
      <div className="logo">
        {logo ? <img src={`${logo}`} alt="Logo" /> : <strong>{title}</strong>}
      </div>

      <div className="infos">
        <h1>
          Resumo do Pedido <span>#{order?.store_order_id}</span>
        </h1>
      </div>
    </Container>
  );
};

export default Header;
