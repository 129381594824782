import { ToastMessage } from 'hooks/toast';

interface ToastsMessages {
  [key: string]: Omit<ToastMessage, 'id'>;
}

export const toastsMessages: ToastsMessages = {
  paymentApproved: {
    type: 'success',
    title: 'Seu pedido foi aceito',
    description: 'Tudo certo. Em breve seu pedido estará chegando. 🚛',
  },
  paymentRefused: {
    type: 'error',
    title: 'Pagamento não aprovado.',
    description: 'Ocorreu um erro ao fazer o pagamento.',
  },
  paymentError: {
    type: 'error',
    title: 'Erro no pagamento',
    description: 'Ocorreu um erro ao fazer o pagamento.',
  },
  somethingWrong: {
    type: 'info',
    title: 'Algo de errado aconteceu.',
    description: 'Verifique os campos do formulários.',
  },
  issuerNotAllowed: {
    type: 'error',
    title: 'Esta bandeira não é aceita',
    description: 'Você pode tentar com outro cartão.',
  },
};
