const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '576px',
  tablet: '768px',
  laptop: '992px',
  laptopL: '1200px',
  desktop: '1600px',
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,

  mobileS_max: `(max-width: ${size.mobileS})`,
  mobileM_max: `(max-width: ${size.mobileM})`,
  mobileL_max: `(max-width: ${size.mobileL})`,
  tablet_max: `(max-width: ${size.tablet})`,
  laptop_max: `(max-width: ${size.laptop})`,
  laptopL_max: `(max-width: ${size.laptopL})`,
  desktop_max: `(max-width: ${size.desktop})`,
  desktopL_max: `(max-width: ${size.desktop})`,
};

/**
 *
 * Device Category	    BP Width	  Device Name
 * Mobile, portrait     320px	      iPhone SE
 *                      375px	      iPhone 6 to X
 *                      414px	      iPhone 8 Plus
 *
 * Mobile, landscape	  568px	      iPhone SE
 *                      667px	      iPhone 6 to 8
 *                      736px	      iPhone 8 Plus
 *                      812px	      iPhone X
 *
 * Tablet, portrait	    768px	      iPad Air, iPad Mini, iPad Pro 9″
 *                      834px	      iPad Pro 10″
 *
 * Tablet, landscape	  1024px      iPad Air, iPad Mini, iPad Pro 9″
 *                      1024px      iPad Pro 12″ (portrait)
 *                      1112px      iPad Pro 10″
 *
 * Laptop displays	    1366px      HD laptops (768p)
 *                      1366px      iPad Pro 12″ (landscape)
 *                      1440px      13″ MacBook Pro (2x scaling)
 *
 * Desktop displays	    1680px      13″ MacBook Pro (1.5x scaling)
 *                      1920px      1080p displays
 *
 *
 */
