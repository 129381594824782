import React from 'react';
import { FiImage, FiRepeat } from 'react-icons/fi';

import { useOrder } from 'hooks/order';

import { formatMoney } from 'utils/helpers';

import { List, StatusSection, Product } from './styles';

const ProductsList = () => {
  const { items } = useOrder();

  const { confirmed, stockOut, substitution, restItems } = items;

  return (
    <section className="productsList__section">
      <List>
        {restItems?.map(item => (
          <Product key={item.id}>
            <aside className="product__image">
              {/* Badge to show the quantity of the item  */}
              <span className="product__quantity">{item.quantity}</span>

              {/* Product thumbnail */}
              {item?.image_url ? (
                <img src={item.image_url} alt={item.name} />
              ) : (
                <FiImage />
              )}
            </aside>

            {/* Main columns */}
            <div className="product__infos">
              {/* Product name */}
              <div className="product__name">{item.name}</div>
              {/* Product category */}
              {item.category?.name && (
                <span className="product__category">{item.category.name}</span>
              )}
              {/* Single price */}
              <span className="product__singlePrice">
                {formatMoney(item.original_price)}
              </span>
            </div>

            <div className="product__subtotal">
              <div className="value">{formatMoney(item.total_value)}</div>
            </div>
          </Product>
        ))}
        {confirmed?.map(item => (
          <Product key={item.id}>
            <aside className="product__image">
              {/* Badge to show the quantity of the item  */}
              <span className="product__quantity">{item.quantity}</span>

              {/* Product thumbnail */}
              {item?.image_url ? (
                <img src={item.image_url} alt={item.name} />
              ) : (
                <FiImage />
              )}
            </aside>

            {/* Main columns */}
            <div className="product__infos">
              {/* Product name */}
              <div className="product__name">{item.name}</div>
              {/* Product category */}
              {item.category?.name && (
                <span className="product__category">{item.category.name}</span>
              )}
              {/* Single price */}
              <span className="product__singlePrice">
                {formatMoney(item.original_price)}
              </span>
            </div>

            <div className="product__subtotal">
              <div className="value">{formatMoney(item.total_value)}</div>
            </div>
          </Product>
        ))}
        {substitution && (
          <>
            <StatusSection>
              <h2>
                Produtos substituídos{' '}
                <span className="length">({substitution.length})</span>
              </h2>
            </StatusSection>

            {substitution.map(item => (
              <Product key={item.id}>
                <aside className="product__image">
                  {/* Badge to show the quantity of the item  */}
                  <span className="product__quantity">{item.quantity}</span>

                  {/* Product thumbnail */}
                  {item?.image_url ? (
                    <img src={item.image_url} alt={item.name} />
                  ) : (
                    <FiImage />
                  )}
                </aside>

                {/* Main columns */}
                <div className="product__infos">
                  {/* Product name */}
                  <div className="product__originalText">{item.detail}</div>

                  {/* Product category */}
                  {item.name && (
                    <div className="product__substitute">
                      <div className="wrapperIcon">
                        <FiRepeat />
                      </div>
                      <div className="text">{item.name}</div>
                    </div>
                  )}
                  {/* Single price */}
                  <span className="product__singlePrice">
                    {formatMoney(item.original_price)}
                  </span>
                </div>

                <div className="product__subtotal">
                  <div className="value">{formatMoney(item.total_value)}</div>
                </div>
              </Product>
            ))}
          </>
        )}
        {stockOut && (
          <>
            <StatusSection>
              <h2>
                Produtos em falta
                <span className="length">({stockOut.length})</span>
              </h2>
            </StatusSection>

            {stockOut.map(item => (
              <Product key={item.id}>
                <aside className="product__image">
                  {/* Badge to show the quantity of the item  */}
                  <span className="product__quantity">{item.quantity}</span>

                  {/* Product thumbnail */}
                  {item?.image_url ? (
                    <img src={item.image_url} alt={item.name} />
                  ) : (
                    <FiImage />
                  )}
                </aside>

                {/* Main columns */}
                <div className="product__infos">
                  {/* Product name */}
                  <div className="product__name">{item.name}</div>
                  {/* Product category */}
                  {item.category?.name && (
                    <span className="product__category">
                      {item.category.name}
                    </span>
                  )}
                </div>

                <div className="product__subtotal">
                  <div className="stockOut">Em falta</div>
                </div>
              </Product>
            ))}
          </>
        )}
      </List>
    </section>
  );
};

export default ProductsList;
