import { StatusOrder } from 'types/order';

export const STATUS_PENDING = 'order_placed';
export const STATUS_WAITING_PAYMENT = 'waiting_payment';
export const STATUS_APPROVED_VIRTUAL = 'approved'; // Apenas para animação do frontend
export const STATUS_WAINTING_PICKING = 'waiting_picking';
export const STATUS_IN_PICKING = 'in_picking';
export const STATUS_IN_DELIVERY = 'in_delivery';
export const STATUS_APPROVED = 'confirmed_order';
export const STATUS_FINISHED = 'finished';
export const STATUS_CANCELLED = 'order_cancelled';

export const STATUS_PENDING_LABEL = 'Fechar pedido';
export const STATUS_APPROVED_LABEL = 'Pedido aceito';
export const STATUS_FINISHED_LABEL = 'Pedido concluído';
export const STATUS_WAINTING_PICKING_LABEL = 'Aguardando separação';
export const STATUS_IN_DELIVERY_LABEL = 'in_delivery';
export const STATUS_IN_PICKING_LABEL = 'Em separação';
export const STATUS_DEFAULT_LABEL = 'Dados pedido';
export const STATUS_CANCELLED_LABEL = 'Pedido cancelado';

export const PAYMENT_STATUS_APPROVED = 'payment_approved';
export const PAYMENT_STATUS_AUTHORIZED = 'payment_authorized';
export const PAYMENT_STATUS_REFUSED = 'payment_refused';
export const PAYMENT_STATUS_WAITING = 'waiting_payment';

/**
 * Função para checar o status e retornar um texto referente ao status do pedido para usar no botão ou no status do topo
 * @param status Status do pedido
 * @example
 *    const { status } = useOrder();
 *    <strong>{STATUS_LABELS(status)}</strong>
 * @return
 *      String crua com um texto. "Fechar Pedido", "Pedido aceito" ou "Pedido Concluído".
 */
export const STATUS_LABELS = (status: StatusOrder) => {
  switch (status) {
    case STATUS_PENDING:
      return STATUS_PENDING_LABEL;

    case STATUS_APPROVED_VIRTUAL:
      return STATUS_APPROVED_LABEL;

    case STATUS_APPROVED:
      return STATUS_APPROVED_LABEL;

    case STATUS_FINISHED:
      return STATUS_FINISHED_LABEL;

    case STATUS_WAINTING_PICKING:
      return STATUS_WAINTING_PICKING_LABEL;

    case STATUS_IN_DELIVERY:
      return STATUS_IN_DELIVERY_LABEL;

    case STATUS_IN_PICKING:
      return STATUS_IN_PICKING_LABEL;

    case STATUS_WAITING_PAYMENT:
      return STATUS_PENDING_LABEL;

    case STATUS_CANCELLED:
      return STATUS_CANCELLED_LABEL;

    default:
      return STATUS_DEFAULT_LABEL;
  }
};
