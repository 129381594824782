import styled, { css } from 'styled-components';

import { STATUS_PENDING, STATUS_CANCELLED } from 'utils/constants';

export const Container = styled.section`
  ${({ theme }) => css`
    &.${STATUS_CANCELLED} {
      background: ${theme.colors.danger};
    }

    &:not(.${STATUS_CANCELLED}) {
      background: ${theme.colors.success};
    }

    &:not(.${STATUS_PENDING}) {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 10rem;
      width: 100%;
      border: 0;
      color: ${theme.colors.white};
      font-weight: 700;

      svg {
        margin-right: ${theme.spacings.xsmall};
        font-size: ${theme.font.sizes.xlarge};
      }
    }
  `}
`;
