import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

interface Option {
  id: string;
  value: string;
  label: string;
}

interface Props {
  name: string;
  options: Option[];
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

const RadioButtonWithoutBulltet: React.FC<InputProps> = ({
  name,
  options,
  ...rest
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField, defaultValue = [] } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        const checked = refs.find(ref => ref.checked);

        return checked ? checked.value : null;
      },
      clearValue: (refs: HTMLInputElement[]) => {
        refs.forEach(ref => {
          ref.checked = false;
        });
      },

      setValue: (refs: HTMLInputElement[], value) => {
        const item = refs.find(ref => ref.value === value);

        if (item) {
          item.checked = true;
        }
      },
    });
  }, [defaultValue, fieldName, registerField]);

  return (
    <div className="listRadio">
      {options.map((option, index) => (
        <Container className="radioWrapper" key={option.id}>
          <input
            defaultChecked={defaultValue === option.value}
            ref={ref => {
              inputRefs.current[index] = ref as HTMLInputElement;
            }}
            value={option.value}
            type="radio"
            id={option.id}
            name={name}
            {...rest}
          />
          <label htmlFor={option.id} key={option.id}>
            {option.label}
          </label>
        </Container>
      ))}
    </div>
  );
};
export default RadioButtonWithoutBulltet;
