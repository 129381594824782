import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';

import { Container, Error } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  icon?: React.ComponentType<IconBaseProps>;
  handleFocus?: Function;
}

const Input: React.FC<InputProps> = ({
  name,
  icon: Icon,
  handleFocus,
  label,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    if (handleFocus) {
      handleFocus(name);
    }

    setIsFocused(true);
  }, [handleFocus, name]);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container isFocused={isFocused} isFilled={isFilled} isErrored={!!error}>
      {label && label}
      <div>
        {Icon && <Icon />}
        <input
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          type={rest.type}
          defaultValue={defaultValue}
          {...rest}
          ref={inputRef}
        />

        {error && (
          <Error title={error}>
            <FiAlertCircle />
          </Error>
        )}
      </div>
    </Container>
  );
};

export default Input;
