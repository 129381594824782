import styled from 'styled-components';

export interface ContainerStylesProps {
  inline?: boolean;
}

export const Container = styled.div<ContainerStylesProps>`
  display: flex;
  flex-direction: ${props => (props.inline ? 'row' : 'column')};
  justify-content: center;
  align-items: center;
`;
