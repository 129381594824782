import React from 'react';
import { FiMapPin } from 'react-icons/fi';

import { useOrder } from 'hooks/order';

import { Section } from '../Section';

const AddressSection = () => {
  const { customer, address, shipments, location } = useOrder();

  return (
    <Section className="address__section">
      <aside>
        <FiMapPin />
      </aside>

      {shipments?.[0]?.type === 'shipping_from_store' && (
        <div className="infos">
          <span>{customer.name}</span>

          {address ? (
            <>
              <strong>
                {address.street}, {address.number}
              </strong>
              <span className="complement">{address.complement}</span>
            </>
          ) : (
            <span>Nenhum endereço selecionado.</span>
          )}
        </div>
      )}

      {shipments?.[0]?.type === 'pick_up_in_store' && (
        <div className="infos">
          {location ? (
            <>
              <span>Loja: {location.name}</span>
              <strong>
                {location.address_street}, {location.address_number}
              </strong>
              <span className="complement">{location.address_complement}</span>
            </>
          ) : (
            <span>Nenhum local de retirada selecionado.</span>
          )}
        </div>
      )}

      {!shipments?.[0]?.type && (
        <div className="infos">
          <span>{customer.name}</span>

          <span>
            Houve um problema ao carregar o endereço. Entre em contato com a
            loja ou atualize a página.
          </span>
        </div>
      )}
    </Section>
  );
};
export default AddressSection;
