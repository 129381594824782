import React from 'react';
import { Spinner } from '../Spinner';

import { Container } from './styles';

interface Props {
  children?: React.ReactNode;
  inline?: boolean;
  color?: string;
}

const Loading = ({
  children = 'Carregando...',
  inline = false,
  color,
}: Props) => {
  return (
    <Container inline={inline}>
      <Spinner color={color} />
      {children}
    </Container>
  );
};

export default Loading;
