import styled, { css, DefaultTheme } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

const containerModifiers = {
  isFilled: (theme: DefaultTheme) => css`
    border-color: ${theme.colors.hover};
    > svg {
      color: ${theme.colors.primary};
    }
  `,
  isFocused: (theme: DefaultTheme) => css`
    border-color: ${theme.colors.primary};
    > svg {
      color: ${theme.colors.primary};
    }
  `,
  isErrored: (theme: DefaultTheme) => css`
    border-color: ${theme.colors.danger};
  `,
};

export const Container = styled.label<ContainerProps>`
  ${({ theme, isFilled, isFocused, isErrored }) => css`
    display: flex;
    flex-direction: column;

    font-size: ${theme.font.sizes.small};

    & + label {
      margin-top: ${theme.spacings.xsmall};
    }

    > div {
      display: flex;
      flex: 1;
      align-items: center;

      margin-top: ${theme.spacings.xxsmall};

      max-width: 100%;
      width: 100%;
      padding: ${theme.spacings.xsmall};
      border-radius: ${theme.border.radius.medium};

      border: 1px solid ${theme.colors.gray};
      background: ${theme.colors.light};

      transition: ${theme.transition.default} all;

      input {
        font-size: ${theme.font.sizes.medium};
        color: ${theme.colors.dark};
        flex: 1;

        &::placeholder {
          color: ${theme.colors.gray};
        }
      }

      > svg {
        margin-right: ${theme.spacings.medium};
        color: ${theme.colors.dark};
        transition: ${theme.transition.default} all;
      }

      ${isFilled && containerModifiers.isFilled(theme)};
      ${isFocused && containerModifiers.isFocused(theme)};
      ${isErrored && containerModifiers.isErrored(theme)};
    }
  `}
`;

export const Error = styled(Tooltip)`
  height: 2rem;
  margin-left: 1.6rem;

  ${({ theme }) => css`
    --mainColor: ${theme.colors.danger};
    --mainColorText: ${theme.colors.light};
  `}
`;
