import axios from 'axios';
import { useCallback } from 'react';

/**
 * Hook para retornar o IP do usuário.
 *
 * @example
 * const [ipAddress] = useIpAddress();
 * ip_address: await ipAddress(),
 *
 * @return String com o endereço de IP do usuário
 */

const useIpAddress = () => {
  const ipAddress = useCallback(async () => {
    const { data } = await axios.get('https://www.icanhazip.com');

    return data;
  }, []);

  return [ipAddress];
};

export { useIpAddress };
