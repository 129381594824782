import React, {
  forwardRef,
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
} from 'react';

import * as S from './styles';

type ButtonTypes =
  | AnchorHTMLAttributes<HTMLAnchorElement>
  | ButtonHTMLAttributes<HTMLButtonElement>;

export type ButtonProps = {
  template?: 'default' | 'link' | 'success' | 'warning';
  children?: React.ReactNode;
  as?: React.ElementType;
} & ButtonTypes;

const Button: React.ForwardRefRenderFunction<S.WrapperProps, ButtonProps> = (
  { children, template = 'default', ...rest },
  ref
) => {
  return (
    <S.Container type="button" template={template} ref={ref} {...rest}>
      {children}
    </S.Container>
  );
};

export default forwardRef(Button);
