import styled, { css, DefaultTheme } from 'styled-components';
import { ButtonProps } from '.';

export type WrapperProps = Pick<ButtonProps, 'template'>;

const buttonTemplates = {
  default: (theme: DefaultTheme) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: ${theme.border.radius.medium};
    border: 1px solid transparent;

    padding: 0 ${theme.spacings.medium};
    width: 100%;
    height: calc(${theme.spacings.large} * 2.25);

    background: ${theme.colors.success};
    color: ${theme.colors.white};

    svg {
      margin-right: 0.5rem;
    }

    &:hover {
      &:not(:disabled) {
        background-color: ${theme.colors.successShade20};
      }
    }

    &:disabled {
      cursor: default;
      opacity: 0.75;
    }

    &:focus {
      border-color: ${theme.colors.success};
    }
  `,
  link: (theme: DefaultTheme) => css`
    color: ${theme.colors.primary};
  `,
  warning: (theme: DefaultTheme) => css`
    ${buttonTemplates.default(theme)}

    background: ${theme.colors.warning};
    &:hover {
      &:not(:disabled) {
        background-color: ${theme.colors.warningShade20};
      }
    }

    &:focus {
      border-color: ${theme.colors.warning};
    }
  `,
};

export const Container = styled.button<WrapperProps>`
  ${({ theme, template }) => css`
    font-weight: ${theme.font.bolder};
    font-size: ${theme.font.sizes.small};
    transition: all ${theme.transition.fast};
    text-decoration: none;

    ${template === 'default' && buttonTemplates.default(theme)};
    ${template === 'link' && buttonTemplates.link(theme)};
    ${template === 'warning' && buttonTemplates.warning(theme)};
  `}
`;
