import React from 'react';

import { Container } from './styles';

export interface SpinnerStylesProps {
  color?: string;
}

export const Spinner = ({ color: SpinnerColor }: SpinnerStylesProps) => (
  <Container color={SpinnerColor} />
);
