import React from 'react';

import { formatMoney } from 'utils/helpers';
import { useOrder } from 'hooks/order';

import { SectionValues } from './styles';

const ValuesList = () => {
  const { values } = useOrder();

  return (
    <SectionValues className="values">
      {/* Subtotal */}
      <div className="values__subtotal">
        <span>Subtotal:</span>
        {formatMoney(values.subtotal)}
      </div>

      {/* Descontos */}
      {values?.discount >= 0.01 && (
        <div className="values__discount">
          <span>Desconto:</span> {formatMoney(values.discount)}
        </div>
      )}

      {/* Taxa de entrega */}
      {values.shipping_cost >= 0.01 && (
        <div className="values__delivery">
          <span>Taxa de entrega:</span> {formatMoney(values.shipping_cost)}
        </div>
      )}

      {/* Taxa de entrega */}
      {values?.service_fee >= 0.01 && (
        <div className="values__delivery">
          <span>Taxa de serviço:</span> {formatMoney(values.service_fee)}
        </div>
      )}

      {/* Total */}
      <div className="values__total">
        <span>Total: </span>
        {formatMoney(values.total)}
      </div>
    </SectionValues>
  );
};

export default ValuesList;
