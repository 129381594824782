import styled, { css } from 'styled-components';

import { STATUS_APPROVED_VIRTUAL, STATUS_CANCELLED } from 'utils/constants';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: 0 auto;
    max-width: ${theme.grid.container};
    min-height: 100vh;
    padding-bottom: 6.4rem;

    background: ${theme.colors.white};

    &.${STATUS_APPROVED_VIRTUAL} {
      padding-top: 0;
    }

    &.${STATUS_CANCELLED} {
      padding-bottom: 0;
    }

    main {
      position: relative;
      z-index: 1;

      display: flex;
      flex-direction: column;
      flex: 1;

      width: 100%;

      background: ${theme.colors.white};
    }
  `}
`;
