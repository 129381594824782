import React from 'react';
import { Helmet } from 'react-helmet-async';

import { useOrder } from 'hooks/order';

/**
 * Alterações na tag head do HTML.
 * Pega do Order Provider: título, logo, order_id e color para setar:
 * @example
 *  <title>Resumo do pedido {order.store_order_id  || ' Carregando...'} </title>
 * <link rel="icon" type="image/png" href={store.logo} />
 * <meta name="msapplication-TileColor" content={store.header_color} />
 * <meta name="theme-color" content={header_color} />
 *
 * Não é SSR.
 */

const MainHelmet = () => {
  const { order, store } = useOrder();

  return order && store ? (
    <Helmet>
      <title>{`Resumo do pedido #${
        order.store_order_id || ' Carregando...'
      } - ${store?.name}`}</title>

      {store?.logo && <link rel="icon" type="image/png" href={store.logo} />}

      {store?.logo && (
        <>
          <meta name="msapplication-TileColor" content={store.header_color} />
          <meta name="theme-color" content={store.header_color} />
        </>
      )}
    </Helmet>
  ) : (
    <Helmet />
  );
};

export default MainHelmet;
