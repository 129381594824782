import React, { createContext, useContext, useState } from 'react';
import { Detector } from 'react-detect-offline';

interface ConnectionContextData {
  isOnline: boolean;
}

const ConnectionContext = createContext<ConnectionContextData>(
  {} as ConnectionContextData
);

const ConnectionProvider: React.FC = ({ children }) => {
  const [isOnline, setIsOnline] = useState<boolean>(true);

  return (
    <ConnectionContext.Provider value={{ isOnline }}>
      <Detector
        onChange={online => setIsOnline(online)}
        render={() => <></>}
        polling={{
          enabled: true,
          url: 'https://www.icanhazip.com/',
          interval: 5000,
          timeout: 5000,
        }}
      />
      {children}
    </ConnectionContext.Provider>
  );
};

function useConnection(): ConnectionContextData {
  const context = useContext(ConnectionContext);

  if (!context) {
    throw new Error('useConnection must be used within an ConnectionProvider');
  }

  return context;
}

export { ConnectionProvider, useConnection };
