import styled, { css } from 'styled-components';

export const Container = styled.div`
  /* display: none; */

  ${({ theme }) => css`
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;

      * {
        cursor: not-allowed;
      }

      label {
        &:hover {
          color: inherit;
        }
      }
    }

    &.hide,
    input[type='radio'] {
      display: none;
    }

    /* Input */
    input[type='radio']:checked,
    &.selected input[type='radio'] {
      + label {
        border-color: ${theme.colors.primary};
        color: ${theme.colors.primary};
        background: ${theme.colors.white};
      }
    }

    /* Label */
    label {
      display: flex;
      flex: auto;
      align-items: center;
      justify-content: center;

      padding: ${theme.spacings.xsmall} ${theme.spacings.medium};
      height: 100%;
      border-radius: ${theme.border.radius.large};
      border: 2px solid ${theme.colors.gray};

      background: ${theme.colors.light};

      color: ${theme.colors.gray};
      font-size: ${theme.font.sizes.small};
      font-weight: ${theme.font.normal};

      transition: all ${theme.transition.default};
      cursor: pointer;

      &:hover {
        color: ${theme.colors.primary};
      }
    }
  `}
`;
