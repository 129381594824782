import Loading from 'components/Loading';
import { useOrder } from 'hooks/order';
import useAme from 'hooks/useAme';
import { isDesktop, isMobile } from 'react-device-detect';
import React, { useEffect, useRef } from 'react';
import { FiAlertCircle, FiCloudOff } from 'react-icons/fi';

import { useParams } from 'react-router-dom';
import { URLParamTypes } from 'types/URLParamTypes';
import { useConnection } from 'hooks/connection';
import Button from 'components/Button';
import * as S from './styles';

const Ame: React.FC = () => {
  const { formattedTotalValue, getOrderData, status } = useOrder();
  const { qrCode, deepLink, cashback, loading, error } = useAme();
  const { id, host } = useParams<URLParamTypes>();
  const { isOnline } = useConnection();

  const refreshTimer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    refreshTimer.current = setInterval(async () => {
      getOrderData({ id, host });
    }, 10000);

    return () => {
      clearInterval(refreshTimer.current as NodeJS.Timeout);
    };
  }, [getOrderData, host, id]);

  return (
    <>
      <S.Container>
        <div className="ame-qrcode-wrapper">
          <img
            src="https://api.amedigital.com/vtex-checkout/images/logo-ame.svg"
            className="logo"
            alt="Ame digital"
          />

          <div className="container">
            <div className="payment-values">
              <strong>Valor da compra:</strong>
              <strong>
                <span>{formattedTotalValue}</span>
              </strong>

              {cashback && (
                <p>
                  Receba de volta <strong>{cashback}</strong> em até 30 dias.
                </p>
              )}
            </div>

            {!qrCode && loading && (
              <div className="loading">
                <Loading inline>Carregando Ame...</Loading>
              </div>
            )}

            {isDesktop && (
              <>
                {qrCode && !loading && (
                  <img
                    className="qrcode"
                    src={qrCode}
                    alt="QR Code Ame Digital"
                  />
                )}

                {!qrCode && !loading && error && (
                  <div className="alert error">
                    <div className="icon">
                      <FiAlertCircle />
                    </div>
                    <strong>Houve um erro ao carregar o QR Code</strong>
                  </div>
                )}

                <div className="payment-info">
                  <strong>Pagar com a AME é mais fácil e rápido!</strong>

                  <ul>
                    <li>
                      1. Abra o aplicativo Ame Digital. Se você ainda
                      <p>
                        <span>não tem, baixe no</span>
                        <img
                          src="https://api.amedigital.com/vtex-checkout/images/google-logo.svg"
                          alt="Google Play"
                        />
                        <span>ou na</span>
                        <img
                          src="https://api.amedigital.com/vtex-checkout/images/apple-logo.svg"
                          alt="App Store"
                        />
                      </p>
                    </li>
                    <li>
                      <p>
                        2. Clique em <strong>pagar</strong>
                        <img
                          src="https://api.amedigital.com/vtex-checkout/images/ame-small-logo.svg"
                          alt="Ame Logo"
                        />
                        e aponte para o QR Code.
                      </p>
                    </li>
                    <li>
                      3. Pronto, agora é só finalizar o pagamento com{' '}
                      <strong>Ame</strong>.
                    </li>
                  </ul>

                  <a
                    href="https://www.amedigital.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Quer saber mais sobre a Ame? Clique aqui.
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      </S.Container>

      <section className={`submit fixed ${status.type}`}>
        {deepLink && isMobile && (
          <Button
            className={`${status.type}`}
            href={deepLink}
            as="a"
            target="_blank"
            rel="noopener noreferrer"
          >
            Finalizar compra
          </Button>
        )}

        {!isOnline && (
          <Button
            className={`${status.type}`}
            type="button"
            disabled
            template="warning"
          >
            <Loading inline color="#ffffff">
              <FiCloudOff /> Sem conexão com a internet
            </Loading>
          </Button>
        )}
      </section>
    </>
  );
};

export default Ame;
