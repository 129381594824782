import styled from 'styled-components';
import { device } from 'styles/device';

export const Container = styled.div`
  position: relative;
  z-index: 1;

  --mainColor: var(--primary);
  --mainColorText: var(--white);

  svg {
    color: var(--mainColor);
    height: 1.6rem;
    width: 1.6rem;
  }

  span {
    width: 16rem;
    text-align: center;
    background: var(--mainColor);
    padding: 0.8rem;
    border-radius: 0.4rem;
    font-size: 1.4rem;
    font-weight: 500;
    opacity: 0;
    visibility: hidden;

    transition: all 0.4s;

    position: absolute;
    bottom: calc(100% + 0.8rem);
    right: -1.3rem;
    /* left: 50%; */
    /* transform: translateX(-50%); */

    color: var(--mainColorText);

    &::before {
      position: absolute;
      top: 100%;
      right: 1.6rem;
      /* left: 50%; */
      /* transform: translateX(-50%); */
      border-color: var(--mainColor) transparent;
      border-width: 0.675rem 0.675rem 0 0.675rem;
      border-style: solid;

      border-bottom-width: 0;
      content: '';
    }
  }

  svg {
    &:hover + span {
      opacity: 1;
      visibility: visible;
    }
  }

  @media ${device.tablet_max} {
    span {
      opacity: 1;
      visibility: visible;
      background: transparent;
      color: var(--mainColor);
      width: auto;
      text-align: right;
      white-space: nowrap;
      font-size: 1.2rem;

      &::before {
        display: none;
      }
    }
  }
`;
