import { shade, lighten } from 'polished';

export default {
  grid: {
    container: '80rem',
  },
  border: {
    radius: {
      small: '0.2rem',
      medium: '0.4rem',
      large: '0.8rem',
      rounded: '50%',
    },
  },
  colors: {
    primary: `#3c3584`,
    secondary: `#ffb450`,

    light: `#f8f9fa`,
    dark: `#354052`,

    hover: `#5b51c8`,
    inative: `#f3f3f3`,

    white: `#fff`,
    gray: `#aeb1b5`,
    tertiary: `#ddd`,

    success: `#4BBF73`,
    successLighten40: `${lighten(0.4, '#4BBF73')}`,
    successShade20: `${shade(0.2, '#4BBF73')}`,

    info: `#1f9bcf`,
    infoLighten50: `${lighten(0.5, '#1f9bcf')}`,

    warning: `#e08300`,
    warningShade20: `${shade(0.2, '#e08300')}`,

    danger: `#d9534f`,
    dangerLighten40: `${lighten(0.4, '#d9534f')}`,
  },
  font: {
    family:
      " 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif",
    light: 400,
    normal: 500,
    bold: 600,
    bolder: 700,
    sizes: {
      xxsmall: '1rem',
      xsmall: '1.2rem',
      small: '1.4rem',
      medium: '1.6rem',
      large: '1.8rem',
      xlarge: '2.0rem',
      xxlarge: '2.8rem',
    },
  },
  spacings: {
    xxsmall: '0.4rem',
    xsmall: '0.8rem',
    small: '1.2rem',
    medium: '1.6rem',
    large: '2.0rem',
    xlarge: '2.4rem',
    xxlarge: '3.2rem',
  },
  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 50,
  },
  transition: {
    default: '0.3s ease-in-out',
    fast: '0.1s ease-in-out',
  },
} as const;
