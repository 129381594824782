import {
  createGlobalStyle,
  css,
  DefaultTheme,
  GlobalStyleComponent,
} from 'styled-components';

import { shade, lighten } from 'polished';

type GlobalStylesProps = {};

const GlobalStyles: GlobalStyleComponent<
  GlobalStylesProps,
  DefaultTheme
> = createGlobalStyle`
  :root{
    --primary: #3c3584;
    --secondary: #ffb450;

    --success: #4bbf73;
    --successLighten40: ${lighten(0.4, '#4bbf73')};
    --successShade20: ${shade(0.2, '#4bbf73')};

    --info: #1f9bcf;
    --infoLighten50: ${lighten(0.5, '#1f9bcf')};

    --warning: #e08300;
    --warningShade20: ${shade(0.2, '#e08300')};

    --danger: #d9534f;
    --dangerLighten40: ${lighten(0.4, '#d9534f')};

    --light: #f8f9fa;
    --dark: #354052;

    --hover: #5b51c8;
    --inative: #f3f3f3;

    --white: #fff;
    --gray: #aeb1b5;
    --tertiary: #ddd;
  }

  html {
      font-size: 62.5%;
    }

  html, body{
    min-height: 100%;
    scroll-behavior: smooth;
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &::before,
    &::after {
      box-sizing: inherit;
    }
  }

  ${({ theme }) => css`
    body {
      background: ${theme.colors.light};
    }

    body,
    input,
    button {
      color: ${theme.colors.dark};
      font-family: ${theme.font.family};
      font-size: ${theme.font.sizes.medium};
    }
  `}

  input,
  button {
    cursor: pointer;
    padding: 0;
    border: 0;
    background: transparent;
  }

  ul, li{
    list-style: none;
  }
`;

export default GlobalStyles;
