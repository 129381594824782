import { PaymentMethods } from 'types/order';
import { RadioOption } from './types';

const paymentOptions: RadioOption[] = [
  { id: 'online_payment', value: 'card', label: 'Cartão de crédito' },
  { id: 'wallet_payment', value: 'ame', label: 'Pagar com Ame' },
];

const handlePaymentOptions = (paymentMethods: PaymentMethods) => {
  const options = [];

  if (paymentMethods.card) {
    options.push(paymentOptions[0]);
  }

  if (paymentMethods.ame) {
    options.push(paymentOptions[1]);
  }

  return options;
};

export { paymentOptions, handlePaymentOptions };
