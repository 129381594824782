import styled, { css } from 'styled-components';

export const List = styled.dl`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.medium};
  `}
`;

export const StatusSection = styled.dt`
  ${({ theme }) => css`
    padding: ${theme.spacings.small} ${theme.spacings.medium} 0;
    margin-top: ${theme.spacings.medium};
    h2 {
      font-size: ${theme.font.sizes.medium};

      .length {
        color: ${theme.colors.gray};
        font-size: ${theme.font.sizes.small};
      }
    }
  `}
`;

export const Product = styled.dd`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    padding: ${theme.spacings.small} ${theme.spacings.medium};

    background-color: ${theme.colors.white};

    border-bottom: 1px solid ${theme.colors.tertiary};

    aside {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      margin-right: ${theme.spacings.small};

      width: ${theme.spacings.xxlarge};
      min-width: ${theme.spacings.xxlarge};
      height: ${theme.spacings.xxlarge};

      background-color: ${theme.colors.light};

      img {
        width: ${theme.spacings.xxlarge};
        height: ${theme.spacings.xxlarge};
        object-fit: cover;
        overflow: hidden;
      }

      svg {
        font-size: ${theme.font.sizes.xlarge};
      }

      .product__quantity {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);

        display: flex;
        align-items: center;
        justify-content: center;

        min-width: 1.6rem;
        height: 1.6rem;
        padding: 0 ${theme.spacings.xxsmall};

        border-radius: ${theme.border.radius.large};

        background: ${theme.colors.primary};

        color: ${theme.colors.white};
        font-size: ${theme.font.sizes.xxsmall};
        font-weight: ${theme.font.bolder};
        text-align: center;
      }
    }

    .product__infos {
      display: inline-flex;
      flex-direction: column;
      flex: 1;

      padding-right: ${theme.spacings.xxsmall};

      .product {
        &__name {
          display: inline-flex;
          align-items: center;

          line-height: 1.125;

          font-size: ${theme.font.sizes.small};
          font-weight: ${theme.font.bold};
        }

        &__substitute {
          display: inline-flex;
          align-items: center;

          .wrapperIcon {
            display: inline-flex;
            align-items: center;
            margin-right: ${theme.spacings.xsmall};

            padding: ${theme.spacings.xxsmall} ${theme.spacings.xsmall};
            border-radius: ${theme.border.radius.medium};

            background-color: ${theme.colors.inative};

            color: ${theme.colors.primary};
            font-size: ${theme.font.sizes.small};
          }

          .text {
            display: inline-flex;
            flex-direction: column;

            line-height: 1.125;
            font-size: ${theme.font.sizes.small};
            font-weight: ${theme.font.bold};
          }
        }

        &__originalText {
          line-height: normal;

          margin-bottom: ${theme.spacings.xxsmall};
          font-size: ${theme.font.sizes.small};
          font-weight: ${theme.font.light};

          color: ${theme.colors.danger};
        }
      }

      span {
        margin-top: ${theme.spacings.xxsmall};
        line-height: 1.125;
        font-size: ${theme.font.sizes.xsmall};
        font-weight: ${theme.font.light};
      }
    }

    .product__subtotal {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      min-width: 8rem;

      font-size: ${theme.font.sizes.small};

      .product__singlePrice {
        margin-top: ${theme.spacings.xxsmall};
        font-size: ${theme.font.sizes.xsmall};
        font-weight: ${theme.font.light};
      }

      .value {
        margin-left: auto;
        white-space: nowrap;

        font-size: ${theme.font.sizes.small};
        font-weight: ${theme.font.bold};
        letter-spacing: -0.75px;
      }

      .stockOut {
        margin-left: auto;
        white-space: nowrap;

        padding: ${theme.spacings.xxsmall} ${theme.spacings.xsmall};
        border-radius: ${theme.border.radius.medium};

        background-color: ${theme.colors.inative};

        color: ${theme.colors.danger};
        font-size: ${theme.font.sizes.small};
        font-weight: ${theme.font.bold};
      }
    }
  `}
`;
