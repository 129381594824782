import React, { useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import {
  FiAlertCircle,
  FiCheckCircle,
  FiInfo,
  FiXCircle,
} from 'react-icons/fi';

import { ToastMessage, useToast } from 'hooks/toast';

import { AnimatedContainer, Container } from './styles';

interface ToastProps {
  message: ToastMessage;
  style: Record<string, unknown>;
}

const icons = {
  info: <FiInfo size={24} />,
  error: <FiAlertCircle size={24} />,
  success: <FiCheckCircle size={24} />,
};

const Toast: React.FC<ToastProps> = ({ message, style }) => {
  const { removeToast } = useToast();

  const { Icon } = message;

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [message.id, removeToast]);

  const handlers = useSwipeable({
    trackMouse: true,
    onSwipedRight: () => removeToast(message.id),
  });

  return (
    <AnimatedContainer style={style} {...handlers}>
      <Container type={message.type} hasDescription={!message.description}>
        {Icon ? <Icon /> : icons[message.type || 'info']}

        <div>
          <strong>{message.title}</strong>
          {message.description && <p>{message.description}</p>}
        </div>
        <button onClick={() => removeToast(message.id)} type="button">
          <FiXCircle size={18} />
        </button>
      </Container>
    </AnimatedContainer>
  );
};

export default Toast;
