import React from 'react';
import { addHours } from 'date-fns';
import { format } from 'date-fns-tz';

// import ptBR from 'date-fns/locale/pt-BR';
import { FiTruck } from 'react-icons/fi';

import { useOrder } from 'hooks/order';
import { formatMoney } from 'utils/helpers';

import { Section } from '../Section';

const ShipmentsSection: React.FC = () => {
  const { shipments, values } = useOrder();

  const formattedDate = (date: string, schedule_time: string) => {
    const dateFormatted = addHours(new Date(date), 3);

    return format(dateFormatted, 'dd/MM/yyyy');

    // return formatRelative(dateFormatted, new Date(), {
    //   locale: ptBR,
    // });
  };

  const shipmentType = (shipmentType: string) =>
    shipmentType === 'shipping_from_store' ? 'Entrega' : 'Retirada';

  return (
    <>
      {shipments.length >= 1 ? (
        shipments.map(item => (
          <Section className="shipping" key={item.id}>
            <aside>
              <FiTruck />
            </aside>

            <div className="infos">
              <strong>
                {formattedDate(item.schedule_date, item.schedule_time)} -{' '}
                {item.slot}
              </strong>

              <span className="tag">Método: {shipmentType(item.type)}</span>

              {values.shipping_cost > 0 && (
                <span className="complement">
                  Taxa de entrega:{' '}
                  {formatMoney(values.shipping_cost) || 'Não definida'}
                </span>
              )}
            </div>
          </Section>
        ))
      ) : (
        <Section className="shipping">
          <>
            <aside>
              <FiTruck />
            </aside>

            <div className="infos">
              <span className="complement">Forma de entrega não definida</span>
            </div>
          </>
        </Section>
      )}
    </>
  );
};

export default ShipmentsSection;
