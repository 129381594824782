import styled, { css } from 'styled-components';

import { Section } from '../Section';

export const SectionValues = styled(Section)`
  ${({ theme }) => css`
    flex-direction: column;

    > div {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &.values {
      /* border-top: 1px solid ${theme.colors.tertiary}; */
      border-bottom: 0;

      > :not(:last-child) {
        font-size: ${theme.font.sizes.small};
        font-weight: ${theme.font.light};
      }

      .values__total {
        font-weight: ${theme.font.bolder};
        margin-top: ${theme.font.sizes.small};
      }
    }
  `}
`;
