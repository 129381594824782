import React, { useEffect, useState } from 'react';

import { useConnection } from 'hooks/connection';
import { useToast } from 'hooks/toast';
import { FiCloud, FiCloudOff } from 'react-icons/fi';

const ConnectionSection = () => {
  const [status, setStatus] = useState('online');

  const { isOnline } = useConnection();

  const { addToast } = useToast();

  useEffect(() => {
    if (status === 'online' && !isOnline) {
      addToast({
        type: 'error',
        title: 'Sem conexão com a internet',
        description: 'Verifique se sua conexão está ativa.',
        Icon: FiCloudOff,
      });

      setStatus('offline');
    }
    if (status === 'offline' && isOnline) {
      addToast({
        type: 'success',
        title: 'Conexão reestabelecida',
        description: 'Tudo certo, podemos continuar.',
        Icon: FiCloud,
      });

      setStatus('online');
    }
  }, [isOnline, addToast, status]);

  return <></>;
};

export default ConnectionSection;
