import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;

      * {
        cursor: not-allowed;
      }

      label {
        &:hover {
          color: inherit;
        }
      }
    }

    &.hide,
    input[type='checkbox'] {
      display: none;
    }

    /* Input */
    input[type='checkbox']:checked,
    &.selected input[type='checkbox'] {
      /* The .selected class is used in the radio list */
      + label:before {
        border-color: ${theme.colors.primary};
      }

      + label:after {
        width: 1rem;
        height: 1rem;
      }

      + label {
        color: ${theme.colors.primary};

        strong {
          color: ${theme.colors.primary};
        }
        span {
          color: ${theme.colors.dark};
        }
      }
    }

    /* Label */
    label {
      position: relative;

      display: flex;
      align-items: center;
      flex: auto;

      border-radius: ${theme.border.radius.medium};
      padding: 0 ${theme.spacings.medium} 0 2.6rem;
      height: 100%;

      font-size: ${theme.font.sizes.small};

      transition: all ${theme.transition.default};
      cursor: pointer;

      &:hover {
        color: ${theme.colors.primary};
      }

      &:before,
      &:after {
        position: absolute;
        content: '';
        transition: all ${theme.transition.default};
      }

      &:before {
        left: 0;
        top: 50%;
        transform: translateY(-50%);

        width: ${theme.spacings.large};
        height: ${theme.spacings.large};
        border-radius: ${theme.border.radius.medium};
        border: 2px solid ${theme.colors.primary};

        background-color: ${theme.colors.light};
      }

      &:after {
        top: 50%;
        left: 1rem;
        transform: translate(-50%, -50%);
        border-radius: ${theme.border.radius.small};

        width: 0;
        height: 0;

        background: ${theme.colors.success};
      }
    }
  `}
`;
