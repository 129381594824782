import axios from 'axios';
import { URLParamTypes } from 'types/URLParamTypes';

export const apiCheckout = axios.create({
  baseURL: 'https://api.newtail.com.br/checkout/simple-checkout/',
});

export const getOrder = ({ id, host }: URLParamTypes) =>
  apiCheckout.get(`/${id}${host ? `?host=${host}.newtail.com.br` : ''}`);

interface PaymentPostData extends URLParamTypes {
  data: Object;
}

export const postPayment = ({ id, host, data }: PaymentPostData) =>
  apiCheckout.post(
    `/${id}/payment${host ? `?host=${host}.newtail.com.br` : ''}`,
    data
  );

export interface WalletPostData extends URLParamTypes {
  body: {
    payment_info: {
      wallet: string;
    };
  };
}

export const getQrCodeWalletAme = ({ id, host, body }: WalletPostData) =>
  axios.post(
    `https://api.newtail.com.br/checkout/wallet-checkout/${id}/payment${
      host ? `?host=${host}.newtail.com.br` : ''
    }`,
    body
  );
